.custom-icon-button {
    background: transparent none repeat scroll 0 0;
    border-image: none;
    border: 0px solid #a1a5aa;
    color: #000;
    cursor: pointer;
    font-size: 20px;
    padding: 0 10px;
}

.location-block {
  position: relative;

  &-menu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    padding: 8px 0 8px 0;
    background-color: rgb(0,0,0,0.7);
    display: none;

    span {
      color: #FFF;
      font-size: 1rem;
    }
  }
  &:hover &-menu {
    display: flex;
  }
  @media #{$xs-layout} {
    &-menu {
      display: flex;
    }
  }
}

.custom-button {
  transition: all 0.3s;
  color: #fff;
  font-size: 16px;
  padding: 0 10px;
  border: none;
  background-color: $theme-color;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  min-height: 45px;
  line-height: 45px;
  min-width: 120px;
  font-family: 'Matter', 'Inter', sans-serif;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    background-color: #a1a5aa;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Matter', 'Inter', sans-serif;
    gap: 5px;

    span {
      font-family: 'Inter';
    }

    // font-variant-numeric: normal;
    // -webkit-font-feature-settings: "liga" 0;
    // font-feature-settings: "liga" 0;
    // font-family: 'GenYoGothic', sans-serif;
  }
}

.custom-select {
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* Standard syntax */

  /* Custom arrow styles */
  background-image: url('../../assets/img/down-arrow.png');
  background-repeat: no-repeat;
  background-position: right 10px center; /* Adjust the right padding as needed */
  background-size: 12px 12px; /* You can set the size of your arrow */

  padding-right: 30px; /* Make sure this is enough space for the arrow */
  /* Other styling here */
  border: 1px solid #ccc; /* Example border */
  border-radius: 5px; /* Example border-radius */
  padding: 5px 8px; /* Example padding */
}

/* To remove the default arrow for Internet Explorer 10 and 11 */
.custom-select::-ms-expand {
  display: none;
}