.order-detail-area {
    h2 {
        font-size: 18px;
    }

    .product-thumbnail {
        width: 95px;
        padding: 20px 8px 20px 0;
        img {
            width: 100px;
            height: auto;
            min-width: 50px;
        }
    }
}