// Font Face
@font-face {
    font-family: 'Matter';
    src: url('../../assets/fonts/Matter-Bold.otf') format('opentype');
    font-weight: 700; // Bold weight
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('../../assets/fonts/Matter-Light.otf') format('opentype');
    font-weight: 300; // Light weight
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Matter';
    src: url('../../assets/fonts/Matter-Regular.otf') format('opentype');
    font-weight: 400; // Regular weight
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'GenYoGothic';
    src: url('../../assets/fonts/GenYoGothicTW-B.woff2') format('woff2');
    font-weight: 700; // Bold weight
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'GenYoGothic';
    src: url('../../assets/fonts/GenYoGothicTW-EL.woff2') format('woff2');
    font-weight: 200; // Extra Light weight
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'GenYoGothic';
    src: url('../../assets/fonts/GenYoGothicTW-H.woff2') format('woff2');
    font-weight: 800; // Heavy weight
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'GenYoGothic';
    src: url('../../assets/fonts/GenYoGothicTW-L.woff2') format('woff2');
    font-weight: 300; // Light weight
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'GenYoGothic';
    src: url('../../assets/fonts/GenYoGothicTW-M.woff2') format('woff2');
    font-weight: 500; // Medium weight
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'GenYoGothic';
    src: url('../../assets/fonts/GenYoGothicTW-N.woff2') format('woff2');
    font-weight: 400; // Normal weight
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'GenYoGothic';
    src: url('../../assets/fonts/GenYoGothicTW-R.woff2') format('woff2');
    font-weight: 600; // Semi-bold (Regular) weight
    font-style: normal;
    font-display: swap;
}
  

// Font Family
$poppins: "Poppins", sans-serif;
$abrilfatface: "Abril Fatface", cursive;
$cormorantgaramond: "Cormorant Garamond";
$greatvibes: "Great Vibes", cursive;
$lobster: "Lobster", cursive;
$josefin: "Josefin Sans", sans-serif;
$matter: "Matter", sans-serif;
$genyogothic: "GenYoGothic", sans-serif;
$mainfont: "Matter", "GenYoGothic", sans-serif;

// text Colors ---------------

$white: #ffffff;
$black: #000000;
$grey: #f6f7f8;
$red: #f6f7f8;
$green: #f6f7f8;

// bg Colors ---------------

$bg-white: #ffffff;
$bg-black: #000000;
$bg-grey: #f6f6f8;
$bg-purple: #f0e0ff;

// Heading Color
$heading-color: #000000;

// Theme Color
// $theme-color: #a749ff;
// $theme-color: #891717;
$theme-color: #a1b9c3;
$theme-black: #212427;

// Responsive Variables
$xx-layout: "only screen and (min-width: 1366px) and (max-width: 1600px)";
$xl-layout: "only screen and (min-width: 1200px) and (max-width: 1365px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs-layout: "only screen and (max-width: 767px)";
$sm-layout: "only screen and (min-width: 576px) and (max-width: 767px)";
$xxs-layout: "only screen and (min-width: 320px) and (max-width: 479px)";
